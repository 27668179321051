import { currentMemberState } from "../atoms/memberAtoms";
import {useRecoilState} from 'recoil';
import Header from "../components/Header";
import { AquaBtn, AquaBtnNonAbsolte, BackGroundImg, BackGroundUrl, MainAquaFont, MainWhiteFont } from "../styles/MainStyled";
import { useQuery } from "react-query";
import { getMember } from "../api/auth/authApi";
import { useEffect } from "react";

const Intro = () => {

    const moveMatch = () => {
        window.location.href='/match/start';
    }


    const [currentMember, setCurrentMember] = useRecoilState(currentMemberState);

    const {data : memberData, refetch : memberRefetch} = 
        useQuery("member", () => getMember({memberId : currentMember.member_id?currentMember.member_id:0}, currentMember),
        {
            cacheTime : 5 * 60 * 1000,
            retry : 0
        });

    useEffect(() => {
        const saveToken = localStorage.getItem("accessToken");
        if(saveToken) setCurrentMember({
                ...currentMember,
                token : saveToken
            });
        
            localStorage.removeItem("accessToken");
    }, [currentMember, memberData]);

    useEffect(() => {
        memberRefetch();
    }, []);

    return <div>
        <Header bgColor="white" color="black" logo="/images/logo_black.png" member={{name : memberData?.data?.name, role : memberData?.data?.role_code, username : memberData?.data?.username}}/>
        <BackGroundImg>
            <img src="/images/intro_1.jpg"></img>
        </BackGroundImg>
        <BackGroundImg>
            <img src="/images/intro_2.jpg"></img>
            <AquaBtn onClick={moveMatch} theme={{bottom : "150px"}}>매칭서비스 신청하기</AquaBtn>
        </BackGroundImg>
        
    </div>
}

export default Intro;