import { currentMemberState } from "../atoms/memberAtoms";
import {useRecoilState} from 'recoil';
import Header from "../components/Header";
import { AquaBtn, AquaBtnNonAbsolte, BackGroundImg, BackGroundUrl, MainAquaFont, MainWhiteFont } from "../styles/MainStyled";
import { useQuery } from "react-query";
import { getMember } from "../api/auth/authApi";
import { useEffect } from "react";
import { visitSave } from "../api/member/memberApi";

const Main = () => {

    const moveMatch = () => {
        window.location.href='/match/start';
    }


    const [currentMember, setCurrentMember] = useRecoilState(currentMemberState);

    const {data : memberData, refetch : memberRefetch} = 
        useQuery("member", () => getMember({memberId : currentMember.member_id?currentMember.member_id:0}, currentMember),
        {
            cacheTime : 5 * 60 * 1000,
            retry : 0
        });

    useEffect(() => {
        memberRefetch();
        const saveToken = localStorage.getItem("accessToken");
        if(saveToken) setCurrentMember({
                ...currentMember,
                token : saveToken
            });
        
            localStorage.removeItem("accessToken");
    }, [currentMember, memberData]);

    useEffect(() => {
        visitSave();
    }, []);

    return <div>
        <Header bgColor="white" color="black" logo="/images/logo_black.png" member={{name : memberData?.data?.name, role : memberData?.data?.role_code, username : memberData?.data?.username}}/>
        <BackGroundUrl theme={{imgPath : '/video/banner.mp4'}}>
            <video autoPlay muted loop>
                <source src="/video/banner.mp4" type="video/mp4"></source>
            </video>
            <div className="caption">
            <div>
                <div>
                    <MainAquaFont>AD Bridge 각 분야 전문 담당팀이</MainAquaFont><br/>
                    <MainAquaFont>차별화된 기획력</MainAquaFont><MainWhiteFont>으로</MainWhiteFont><br/>
                    <MainAquaFont>고객님에게 꼭 필요한 영상</MainAquaFont><MainWhiteFont>을</MainWhiteFont><br/>
                    <MainWhiteFont>기획해드립니다.</MainWhiteFont>
                </div>
                <div>
                    <AquaBtnNonAbsolte onClick={moveMatch} >매칭서비스 이용하기</AquaBtnNonAbsolte>
                </div>
            </div>

            </div>
            
        </BackGroundUrl>
        <BackGroundImg>
            <img src="/images/main_1.jpg"></img>
        </BackGroundImg>
        <BackGroundImg>
            <img src="/images/main_2.jpg"></img>
        </BackGroundImg>
        <BackGroundImg>
            <img src="/images/main_3.jpg"></img>
        </BackGroundImg>
        <BackGroundImg>
            <img src="/images/main_4.jpg"></img>
            <AquaBtn onClick={moveMatch} theme={{bottom : "150px"}}>매칭서비스 신청하기</AquaBtn>
        </BackGroundImg>
        
    </div>
}

export default Main;