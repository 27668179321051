import axios from "axios";
import { tokenRenew } from "../auth/authApi";

const axiosInstance = axios.create({
    // baseURL : "http://localhost:8080/adbridge",
    baseURL : "http://adbridge.net:8080/adbridge",
    withCredentials : true,
    timeout: 20000
})

axiosInstance.interceptors.request.use((config) => {
    // console.log("config : " + config);
    return config;
}, 
(error) => {
    console.log("instance Request Errror : " + error);
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
async (response) => {
    let result = response?.data;
    const token = response?.headers['token'];
    if(token) {
        result.data.token = response?.headers['token'];
    }
    // console.log("response : " + JSON.stringify(response));
    return result;
},
async (error) => { //서버 통신시 에러 처리
    let status = error?.status;

    console.log("error : " + JSON.stringify(error));

    let redirect = false;

    
    if(!status) {
        let messageTemp = error?.message;
        messageTemp = messageTemp.replace("Request failed with status code ", "");

        status = Number(messageTemp);
    }

    console.log("status : " + status);

    
    if(!status) {
        // console.log("message : " + error?.message);
        return Promise.reject({
            code : '99999',
            message : error?.message
        })
    }

    // console.log("error.config.url : " + error.config.url);

    if(status == 401 && error.config.url!=='/login') {
        
        // if(error.config.url=='/token/renew') {
        //     alert("다시 로그인 해주세요.");
        //     window.location.href='/login';
        // }
        console.log("code : " + + error.response.data.code);
        console.log("message : " + error.response.data.message);

        

        
        let renewResponse;
        if(error.config.url!=='/token/renew') await tokenRenew() //ACCESS TOKEN 재발급
        .then((obj) => {
            renewResponse = obj;

            const renewToken = renewResponse?.headers['token'];
                const originRequest = error.config;
                //
                originRequest.headers.Authentication = `Authentication Bearer ${renewToken}`;
    
                localStorage.setItem('accessToken', renewToken);
                return axiosInstance(originRequest);
        }) 
        .catch((error) => {
            console.log("renew Error : " + JSON.stringify(error));
            const path = window.location.pathname;
            if(path == '/' || path=='/login' || path=='/signup' || path=='/intro' || path=='/match/start' || path=='/password-search') {
                console.log("비로그인 상태");
                return;
            } else {
                redirect = true;
            }
        })

        if(redirect) {
            alert("다시 로그인 해주세요.");
            await redirectUrl('/login');
        }
    } else if(status == 403) {
        alert(`${error?.response?.data?.message} (CODE : ${error?.response?.data?.code})`);
        await redirectUrl('/');
    }

    // console.log("error : " + JSON.stringify(error));
    // alert(`${error?.message} (CODE : ${error?.code})`);
    return Promise.reject(error?.response?.data);
});

const redirectUrl = (url : string) => {
    window.location.href='/login';
}

export default axiosInstance;