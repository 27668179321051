import { useEffect, useState } from "react";

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { ICurrentMember, currentMemberState } from "../../atoms/memberAtoms";

import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { PasswordCheckParams, PasswordInitParams, getMember, memberInfoCheck, memberPasswordInit } from "../../api/auth/authApi";
import Header from "../../components/Header";
import { CenterContainer, MemberBtnDiv, MemberCenterLogo, MemberInput, MemberInputContainer, MemberLogoUnder, MemberSubBtnDiv, SubTitle } from "../../styles/member/MemberStyles";

const PasswordSearch = () => {

    const [currentMember, setCurrentMember] = useRecoilState(currentMemberState);

    const {data : memberData, refetch : memberRefetch} = 
        useQuery("member", () => getMember({memberId : currentMember.member_id?currentMember.member_id:0}, currentMember),
        {
            cacheTime : 5 * 60 * 1000,
            retry : 0
        });

    useEffect(() => {
        const saveToken = localStorage.getItem("accessToken");
        if(saveToken) setCurrentMember({
                ...currentMember,
                token : saveToken
            });
        
            localStorage.removeItem("accessToken");
    }, [memberData]);

    useEffect(() => {
        memberRefetch();
    }, [])

    const [memberId, setMemberId] = useState(-1);

    const navigate = useNavigate();

    const { register, handleSubmit : handleInfoCheckSubmit, setValue} = useForm<PasswordCheckParams>();

    const { register : initRegister, handleSubmit : handleInit, setValue : setInitValue} = useForm<PasswordInitParams>();
    
    const handleInfoCheck = async (data : PasswordCheckParams) => {

        await memberInfoCheck(data)
        .then((response) => {
            console.log("response : " + JSON.stringify(response));
            setMemberId(response?.data?.memberId);
        })
        .catch((error) => {
            console.log("error : " + JSON.stringify(error));
            alert("에러 : " + error?.message);
            return;
        })
    }



    const handlePasswordInit = async (data : PasswordInitParams) => {
        if(data.password!==data.password_confirm) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }
        await memberPasswordInit(data)
        .then((response) => {
            console.log("response : " + JSON.stringify(response));

            alert("변경되었습니다.");

            navigate('/login');
        })
        .catch((error) => {
            console.log("error : " + JSON.stringify(error));
            alert("에러 : " + error?.message);
            return;
        })
    }

    return <>
        <Header bgColor="white" color="black" logo="/images/logo_black.png" member={{name : memberData?.data?.name, role : memberData?.data?.role_code, username : memberData?.data?.username}}/>

        {memberId===-1?
        <CenterContainer>

            <MemberCenterLogo>
                <img src="/images/logo_black.png"/>
            </MemberCenterLogo>
            <MemberLogoUnder>
                비밀번호 찾기
            </MemberLogoUnder>
            <SubTitle>
                <span>가입하신 정보를 입력해주세요.</span>
            </SubTitle>
        
            <MemberInputContainer>
                <form onSubmit={handleInfoCheckSubmit(handleInfoCheck)}>

                <SubTitle>성함</SubTitle>
                <MemberInput {...register("name", {required : "성함을 입력해주세요."})} type="text" placeholder="성함을 입력해 주세요."/>

                <SubTitle>이메일</SubTitle>
                <MemberInput {...register("email", {required : "이메일을 입력해주세요."})} type="text" placeholder="이메일을 입력해 주세요."/>

                <SubTitle>휴대폰 번호</SubTitle>
                <MemberInput {...register("phone", {required : "휴대폰번호를 입력해주세요."})} type="text" placeholder="01012341234"/>

                <MemberBtnDiv>
                    <button>다음</button>
                </MemberBtnDiv>

                </form>
            </MemberInputContainer>

        </CenterContainer>
        :
        <CenterContainer>

            <MemberCenterLogo>
                <img src="/images/logo_black.png"/>
            </MemberCenterLogo>
            <MemberLogoUnder>
                비밀번호 찾기
            </MemberLogoUnder>
            <SubTitle>
                <span>가입하신 정보를 입력해주세요.</span>
            </SubTitle>
        
            <MemberInputContainer>
                <form onSubmit={handleInit(handlePasswordInit)}>

                <MemberInput {...initRegister("memberId", {required : "성함을 입력해주세요."})} type="hidden" value={memberId} placeholder="성함을 입력해 주세요."/>

                <SubTitle>새로운 비밀번호</SubTitle>
                <MemberInput {...initRegister("password", {required : "성함을 입력해주세요."})} type="password" placeholder="성함을 입력해 주세요."/>

                
                <SubTitle>새로운 비밀번호 확인</SubTitle>
                <MemberInput {...initRegister("password_confirm", {required : "성함을 입력해주세요."})} type="password" placeholder="성함을 입력해 주세요."/>

                <MemberBtnDiv>
                    <button>변경하기</button>
                </MemberBtnDiv>

                </form>
            </MemberInputContainer>

        </CenterContainer>
        }
    </>
}

export default PasswordSearch;