import { get } from 'http';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

//현재 유저 데이터
export interface ICurrentMember {
    member_id? : number; //회원 인덱스
    username? : string; //회원 아이디
    email? : string; //회원 이메일
    name? : string; //회원 성명
    phone? : string; //휴대폰번호
    role_code? : string; //권한 코드
    role_name? : string; //권한명
    token? : string; //ACCESS_TOKEN
}

const {persistAtom} = recoilPersist({
    key : "currentMember",
    storage : localStorage
})


export const currentMemberState = atom<ICurrentMember>({
    key : "currentMember",
    default : {
        member_id : 0,
        email : '',
        role_code : '',
        role_name : '',
        token : ''
    },
    effects_UNSTABLE : [persistAtom]
})

export const currentMemberSelector = selector({
    key : "currentMemberSelector",
    get : ({get}) => {
        return get(currentMemberState)
    }
});
