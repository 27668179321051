import { useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import { CardSpan, Green, MatchingListContainer, MatchingListDataDiv, MatchingListTitleDiv, MatchingTable } from "../../styles/admin/AdminMatchingListStyles";
import { currentMemberState } from "../../atoms/memberAtoms";

import { useRecoilState } from 'recoil';
import { IMatchListData, adminCount, matchingList } from "../../api/match/matchApi";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import { getMember } from "../../api/auth/authApi";

export interface pageParam {
    page : number;
    totalElements : number;
    totalPages : number;
    size : number;
    last : boolean;
}


const AdminMatchingList = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentMember, setCurrentMember] = useRecoilState(currentMemberState);

    const {data : countData, refetch : countRefetch} = 
    useQuery("count", () => adminCount(currentMember), { cacheTime : 5 * 60 * 1000, retry : 0})

    const {data : memberData, refetch : memberRefetch} = 
        useQuery("member", () => getMember({memberId : currentMember.member_id?currentMember.member_id:0}, currentMember),
        {
            cacheTime : 5 * 60 * 1000,
            retry : 0
        });

    // const {isLoading, isError, data} = useQuery<IMatchListData[]>("matchList", () => getMatchingList().then((obj) => obj).catch((err) => []));

    const [matchingData, setMatchingData] = useState<IMatchListData[]>([]);

    
    const [pageData, setPageData] = useState<pageParam>();


    const getMatchingList = async () => {

        let result : IMatchListData[] = [];

        await matchingList(searchParams, currentMember)
        .then((response) => {
            result = response?.data?.content;

            if(!result) result = [];

            setPageData({
                page : response?.data?.number,
                totalElements : response?.data?.totalElements,
                totalPages : response?.data?.totalPages,
                size : response?.data?.size,
                last : response?.data?.last
            })
        })
        .catch((error) => {
            result = [];
        })

        setMatchingData(result);

        return result;
    }

    useEffect(() => {
        if(memberData?.data?.username) getMatchingList();
        console.log("page : " + pageData);
    }, [searchParams, memberData, currentMember]);

    useEffect(() => {
        const saveToken = localStorage.getItem("accessToken");
        if(saveToken) setCurrentMember({
                ...currentMember,
                token : saveToken
            });
        
            localStorage.removeItem("accessToken");

    }, [memberData]);

    useEffect(() => {
        memberRefetch();
    }, []);


    return <div>
        <Header bgColor="black" color="white" logo="/images/logo_white.png" member={{name : memberData?.data?.name, role : memberData?.data?.role_code, username : memberData?.data?.username}}/>

        <MatchingListContainer>

            <MatchingListTitleDiv>
                관리자 페이지
            </MatchingListTitleDiv>

            <MatchingListDataDiv>
                <div>
                    <CardSpan background="#BBBBBB">
                        <p>일일 방문자수</p>
                        <p>{countData?.data?.visitCount}</p>
                    </CardSpan>
                    <CardSpan background="#78d0c8">
                        <p>전체매칭수</p>
                        <p>{countData?.data?.matchingCount}</p>
                    </CardSpan>
                    <CardSpan background="#2cb5a9">
                        <p>확인하지않은 매칭</p>
                        <p>{countData?.data?.noCheckMatchingCount}</p>
                    </CardSpan>
                </div>
                <div>
                    <Green size={30} weight={600}>확인하지 않은 매칭</Green>
                </div>
                <div>
                    <MatchingTable>
                        <tbody>
                        {
                            matchingData.map((m) => {
                                return <tr key={m.id}>
                                    <th className="date">{m.check_yn?m.created_at:<Green weight={700} size={16}>{m.created_at}</Green>}</th>
                                    <th className="title" onClick={() => {window.location.href=`/admin/match/${m.id}`}}>{m.check_yn?m.video_title:<Green weight={700} size={16}>{m.video_title}</Green>}</th>
                                    <th className="name">{m.check_yn?m.member_name:<Green weight={700} size={16}>{m.member_name}</Green>}</th>
                                    <th className="phone">{m.check_yn?m.phone:<Green weight={700} size={16}>{m.phone}</Green>}</th>
                                    <th className="email">{m.check_yn?m.email:<Green weight={700} size={16}>{m.email}</Green>}</th>
                                </tr>
                            })
                        }
                        </tbody>
                    </MatchingTable>
                    
                </div>
                <Page props={pageData}/>
            </MatchingListDataDiv>
        </MatchingListContainer>

    </div>
}

export default AdminMatchingList;