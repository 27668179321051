import { ICurrentMember } from "../../atoms/memberAtoms";
import axiosInstance from "../interceptor/authInterceptor";

export interface LoginParams {
    username : string;
    password : string;
}

export interface getMemberParams {
    memberId : number;
}

export interface PasswordCheckParams {
    name : string;
    email : string;
    phone : string;
}

export interface PasswordInitParams {
    memberId : number;
    password : string;
    password_confirm : string;
}

//로그인 API
export const login = async (params : LoginParams) => {
    const response = await axiosInstance.post('/login', params)
    return response;
}

// 회원 조회  API
export const getMember = async (params : getMemberParams, currentMember : ICurrentMember) => {
    const response = await axiosInstance.get(`/member/${currentMember.member_id}`, {
        headers : {
            Authentication : `Bearer ${currentMember.token}`
        }
    })
    return response;
}

//비밀번호 찾기 -> 회원 정보 확인 API
export const memberInfoCheck = async (params : PasswordCheckParams) => {
    const response = await axiosInstance.post(`/member-info-check`, params)
    return response;
}

// 비밀번호찾기 -> 비밀번호 변경 API
export const memberPasswordInit = async (params : PasswordInitParams) => {
    const response = await axiosInstance.patch(`/member/password-init/${params.memberId}`, params)
    return response;
}







export const tokenCheckTest = async (currentMember : ICurrentMember) => {
    const response = await axiosInstance.get('/member/1', {
        headers : {
            Authentication : `Bearer ${currentMember.token}`
        }
    })
    return response;
}

export const tokenRenew = async () => {
    return await axiosInstance.get('/token/renew', {withCredentials : true});
}