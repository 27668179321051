// import { useQuery } from "react-query";
import Header from "../../components/Header";
import { CenterContainer, MemberBtnDiv, MemberCenterLogo, MemberInput, MemberInputContainer, MemberLogoUnder, MemberSubBtnDiv, SubTitle } from "../../styles/member/MemberStyles"
import { LoginParams, getMember, login } from "../../api/auth/authApi";
import { useEffect } from "react";

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { ICurrentMember, currentMemberState } from "../../atoms/memberAtoms";

import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const Login = () => {

    const [currentMember, setCurrentMember] = useRecoilState(currentMemberState);

    const navigate = useNavigate();

    const {data : memberData, refetch : memberRefetch} = 
        useQuery("member", () => getMember({memberId : currentMember.member_id?currentMember.member_id:0}, currentMember),
        {
            cacheTime : 5 * 60 * 1000,
            retry : 0
        });

    useEffect(() => {    
            const saveToken = localStorage.getItem("accessToken");
            if(saveToken) setCurrentMember({
                    ...currentMember,
                    token : saveToken
                });
            
                localStorage.removeItem("accessToken");
        }, [memberData])    

    useEffect(() => {
        memberRefetch();
    }, []);

    const moveSignup = () => {
        window.location.href='/signup';
    }

    const { register, handleSubmit, setValue} = useForm<LoginParams>();

    const handleLogin = async (data : LoginParams) => {

        let responseMember : ICurrentMember = {

        }
        
        await login(data)
        .then((response) => {
            responseMember = {
                member_id : response?.data?.member_id,
                username : response?.data?.username,
                name : response?.data?.name,
                phone : response?.data?.phone,
                role_code : response?.data?.role_code,
                role_name : response?.data?.role_name,
                token : response?.data?.token
            }

            setCurrentMember({...responseMember});
            navigate('/');
        })
        .catch((error) => {
            console.log("error : " + JSON.stringify(error));
            alert(`${error?.message} (CODE : ${error?.code})`);
            return;
        });
    }

    return <>
        <Header bgColor="black" color="white" logo="/images/logo_white.png" 
            member={{name : memberData?.data?.name, role : memberData?.data?.role_code, username : memberData?.data?.username}}/>
        <CenterContainer>

            <MemberCenterLogo>
                <img src="/images/logo_black.png"/>
            </MemberCenterLogo>
            <MemberLogoUnder>
                전문 기획팀의 기획력으로 차별화된 영상을 제작하세요.
            </MemberLogoUnder>
        
            <MemberInputContainer>
                <form onSubmit={handleSubmit(handleLogin)}>
                <SubTitle>이메일</SubTitle>
                <MemberInput {...register("username", {required : "이메일을 입력해주세요."})} type="text" placeholder="adbridge@naver.com"/>

                <SubTitle>비밀번호<span>(8자리 이상, 영문+숫자 조합)</span></SubTitle>
                <MemberInput {...register("password", {required : "비밀번호를 입력해주세요."})} type="password" placeholder="********"/>

                <MemberBtnDiv>
                    <button>로그인</button>
                </MemberBtnDiv>

                </form>
                <MemberSubBtnDiv>
                    <button onClick={moveSignup}>회원가입</button>
                    <button onClick={() => window.location.href='/password-search'}>비밀번호 찾기</button>
                </MemberSubBtnDiv>
            </MemberInputContainer>

        </CenterContainer>
    </>
    
}


export default Login;