import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { AquaBtnNonAbsolte } from "../../styles/MainStyled";
import { MatchingColumn, MatchingConfirm } from "../../styles/admin/AdminMatchingDetailStyles";
import { CardSpan, Green, MatchingListContainer, MatchingListDataDiv, MatchingListTitleDiv } from "../../styles/admin/AdminMatchingListStyles";
import { ICountData, IMatchDetailData, adminCount, matchingCheck, matchingDetail } from "../../api/match/matchApi";
import { useParams } from "react-router-dom";
import { currentMemberState } from "../../atoms/memberAtoms";

import {useRecoilState} from 'recoil';
import { useQuery } from "react-query";
import { getMember } from "../../api/auth/authApi";


const AdminMatchingDetail = () => {

    const [matchData, setMatchData] = useState<IMatchDetailData>({});

    const [currentMember, setCurrentMember] = useRecoilState(currentMemberState);

    

    const {data : countData, refetch : countRefetch} = 
    useQuery("count", () => adminCount(currentMember), { cacheTime : 5 * 60 * 1000, retry : 0})

    const {data : memberData, refetch : memberRefetch} = 
        useQuery("member", () => getMember({memberId : currentMember.member_id?currentMember.member_id:0}, currentMember),
        {
            cacheTime : 5 * 60 * 1000,
            retry : 0
        });

    const params = useParams();

    const getData = async () => {
        console.log(params);
        let result : IMatchDetailData = {};
        await matchingDetail(Number(params?.id), currentMember)
        .then((response) => {
            result = response?.data;
        })
        .catch((error) => {
            result = {};
        })

        setMatchData(result);
    }

    const checkHandler = async () => {
        await matchingCheck(Number(params?.id), currentMember)
        .then((response) => {
            alert("내용을 확인했습니다.");
            window.location.href='/admin/matches';
        })
    }

    useEffect(() => {
        if(memberData?.data?.username) getData();
    }, [params, memberData, currentMember])

    useEffect(() => {
        const saveToken = localStorage.getItem("accessToken");
        if(saveToken) setCurrentMember({
                ...currentMember,
                token : saveToken
            });
        
            localStorage.removeItem("accessToken");
    }, [memberData])

    useEffect(() => {
        memberRefetch();
    }, []);

    return <div>

<Header bgColor="black" color="white" logo="/images/logo_white.png" member={{name : memberData?.data?.name, role : memberData?.data?.role_code, username : memberData?.data?.username}}/>

<MatchingListContainer>

    <MatchingListTitleDiv>
        관리자 페이지
    </MatchingListTitleDiv>

    <MatchingListDataDiv>
        <div>
            <CardSpan background="#BBBBBB">
                <p>일일 방문자수</p>
                <p>{countData?.data?.visitCount}</p>
            </CardSpan>
            <CardSpan background="#78d0c8">
                <p>전체매칭수</p>
                <p>{countData?.data?.matchingCount}</p>
            </CardSpan>
            <CardSpan background="#2cb5a9">
                <p>확인하지않은 매칭</p>
                <p>{countData?.data?.noCheckMatchingCount}</p>
            </CardSpan>
        </div>
        <div>
            <Green size={30} weight={600}>{matchData.video_title} ({matchData.email})</Green>
        </div>
        <div>
            <MatchingColumn>
                <Green size={25} weight={500}>제작 목적</Green>
                <input type="text" value={matchData.purpose} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>영상스타일</Green>
                <input type="text" value={matchData.style} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>제작 범위</Green>
                <input type="text" value={matchData.scope_list?.map(o => o.name).join(",")} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>제작 편수</Green>
                <input type="text" value={matchData.make_count} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>영상길이</Green>
                <input type="text" value={matchData.video_length} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>희망 납품 일자</Green>
                <input type="text" value={matchData.hope_delivery_date} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>영상 제목</Green>
                <input type="text" value={matchData.video_title} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>업체명</Green>
                <input type="text" value={matchData.company} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>참고영상링크</Green>
                <input type="text" value={matchData.video_link} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>예산범위</Green>
                <input type="text" value={matchData.budget} readOnly></input>
            </MatchingColumn>

            <MatchingColumn>
                <Green size={25} weight={500}>상세 제작 내용</Green>
                <textarea value={matchData?.content}>{matchData?.content}</textarea>
            </MatchingColumn>

        </div>

        <MatchingConfirm>
            <AquaBtnNonAbsolte onClick={checkHandler}>확인</AquaBtnNonAbsolte>
        </MatchingConfirm>
    </MatchingListDataDiv>

    
</MatchingListContainer>

    </div>

}

export default AdminMatchingDetail;